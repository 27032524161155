import React from 'react';

export type NumberOrNull = number | null;
export type StringOrNull = string | null;

export type ChildrenType = React.ReactChild[] | React.ReactChild | null;

export type SelectType = {
  label: string;
  value: number | string;
};

export type TagType = {
  id: number;
  title: string;
};

export type CategoryType = {
  id: number;
  parent_id: NumberOrNull;
  name: string;
};

export type VacancyType = {
  id: number;
  title: string;
  description: string;
  category_id: number;
  country_id: number;
  city_id: number;
  duties: string;
  interviewStages: string;
  workingConditions: string;
  salary: number;
  tags: TagType[];
  currency_id: number;
  checklist_id: number;
  checklist: ChecklistPointType[];
  publicationPeriod: string;
  status: string;
  comment: string;
  confidencePrecent: number;
  touchCount: number;
  positivAnswersCount: number;
  relevantCandidatesCount: number;
  numberOfSentCandidates: number;
  offersCount: number;
  createdAt: string;
  companies: CompaniesType[] | [];
  city: CityType;
  country: CountryType;
  currency: CurrencyType;
  categories: CategoryType[];
  presentation: string;
  candidates?: CandidateType[];
  candidateCount: string | number;
  sharedInfo: SharedDetails[];
  additionalInformation: string | null;
  checklistLink: string | null;
  showGdprLink: boolean;
};

export type SharedDetails = {
  sharedBy: {
    fullName: string;
  };
  user_id: number;
  vacancy_id: number;
  shared_by_id: number;
};

export type ChecklistPointType = {
  id?: number;
  title: string;
  isMustHave: boolean;
  vacancy_id?: number;
};

export type CityType = {
  name: string;
  id: number;
  country_id: number;
};

export type CountryType = {
  name: string;
  name_en: string;
  id: number;
};

export type CurrencyType = {
  name: string;
  id: number;
};

export type CompaniesType = {
  name: string;
  id: number;
  paymentFirstPart: StringOrNull;
  paymentSecondPart: StringOrNull;
  clientType: ClientTypeEnum;
  manager: {
    id: number;
    fullName: string;
    hrdEmail: string;
    email: string;
    hrDirector_id: number;
    hrDirector: UserTypeCommonFields;
  }[];
  vacancies_companies: {
    company_id: number;
  };
};

export type CompanyType = {
  id: number;
  logoUrl: string;
  name: string;
  websiteUrl: string;
  contactPerson: string;
  contactPersonPosition: string;
  email: string;
  contactPersonPhone: string;
  workplacePhotos: string[];
  restzonePhotos: string[];
  teamPhotos: string[];
  youTubeVideoUrl: string[];
  contract: string;
  conractNumber: number;
  contractDate: string;
  paymentFirstPart: string;
  paymentSecondPart: string;
  annualPercentage: string;
  agent_id: number;
  owner_id: number;
  phone: string;
  workingConditions: string;
  manager: ManagerType[];
  owner: any;
  bankDetails: string;
  managerOfCompany: ManagerType;
  isMultiUser: boolean;
  clientType: ClientTypeEnum;
};

export enum UserStatusEnum {
  Active = 'active',
  Disabled = 'disabled',
  Handling = 'handling',
}

export enum UserRoleEnum {
  Admin = 'admin',
  HRD = 'hrDirector',
  Manager = 'manager',
  Recruiter = 'recruiter',
  Agent = 'agent',
  Client = 'client',
  Candidate = 'candidate',
}

export type UserTypeCommonFields = {
  id: number;
  avatar: StringOrNull;
  avatarThumbnail: StringOrNull;
  fullName: string;
  phone: StringOrNull;
  email: StringOrNull;
  linkedinLink: StringOrNull;
  status: UserStatusEnum;
  role: UserRoleEnum;
  emailСonfirmed: boolean;
  isSubclient?: boolean;
  createdAt: string;
  ownerOfCompany?: {
    id: number;
    isMultiUser: boolean;
  };
  admins: {
    id: number;
    email: string;
    fullName: string;
  }[];
};

export type ClientType = UserTypeCommonFields & {
  ownerOfCompany: {
    id: number;
    isMultiUser: boolean;
  };
};

export type RecruiterType = UserTypeCommonFields &
  CommonPaymentType & {
    manager_id: NumberOrNull;
    paymentDetails: StringOrNull;
    agentPercentage: NumberOrNull;
    paymentPercentage: NumberOrNull;
    subrecruiterClosedPositionCost: NumberOrNull;
    numberOfSentCandidates: NumberOrNull;
    costPerAttractedCandidate: NumberOrNull;
    closedPositionCost: NumberOrNull;
    inTeams: TeamType[] | [];
  };

export type EmployeesType = {
  id: number;
  email: UserTypeCommonFields['email'];
  fullName: UserTypeCommonFields['fullName'];
  company?: {
    id: number;
    name: string;
    logoUrl: string;
    isMultiUser: boolean;
  };
};

export type CandidateType = UserTypeCommonFields & {
  recruiter_id: NumberOrNull;
  skills: StringOrNull;
  salaryExpectation: StringOrNull;
  downloadCV: StringOrNull;
  placeOfWork: StringOrNull;
  candidateEmploymentDay: StringOrNull;
  candidateProbationDay: StringOrNull;
  offerAmount: NumberOrNull;
  vacancies: VacancyType[] | [];
  recruiter: {
    fullName: UserTypeCommonFields['fullName'];
    role: UserTypeCommonFields['role'];
    email: UserTypeCommonFields['email'];
  };
  placeToWork: PlaceToWork[];
  specializations: SpecializationType[];
  locations: LocationType[];
  comment: string | null;
  teamCash: number;
};

export type ManagerType = UserTypeCommonFields &
  CommonPaymentType & {
    paymentPercentage: NumberOrNull;
    paymentDetails: StringOrNull;
    agentPercentage: NumberOrNull;
    hrDirector_id: NumberOrNull;
    closedPositionCost: NumberOrNull;
    hrDirector: {
      id: number;
      fullName: UserTypeCommonFields['fullName'];
    } | null;
  };

export type HrDirectorType = UserTypeCommonFields &
  CommonPaymentType & {
    assignedManagers: ManagerType[];
    paymentPercentage: NumberOrNull;
    paymentDetails: StringOrNull;
    agentPercentage: NumberOrNull;
    closedPositionCost: NumberOrNull;
  };

export type CommonPaymentType = {
  paymentPercentage: NumberOrNull;
  paymentDetails: StringOrNull;
  agentPercentage: NumberOrNull;
};

export type SpecializationType = {
  id: number;
  title: string;
  isNew: boolean;
  createdAt: string;
  updatedAt: string;
};

export type TeamOfferCell = {
  data: string;
  color: string;
  description: string;
  link: string;
};

export type TeamOfferWeek = {
  date: string;
  closedVacancies: TeamOfferCell;
  paymentState: TeamOfferCell;
  reportRecruiter: TeamOfferCell;
  reportVacancies: TeamOfferCell;
};

export type TeamOfferMonth = {
  month: string;
  teamWallet: TeamOfferCell;
  weeks: TeamOfferWeek[][];
};

export type TeamOffer = {
  id: number;
  title: string;
  rating: TeamOfferCell;
  cells: TeamOfferMonth[];
};

export type TeamOfferCellBody = TeamOfferCell & {
  field: string;
  date: string;
};

export type TeamOfferBody = {
  title?: string;
  ratingTitle?: string;
  ratingColor?: string;
  ratingDescription?: string;
  ratingLink?: string;
};

export type TeamType = {
  id: number;
  managers:
    | {
        id: number;
        fullName: UserTypeCommonFields['fullName'];
        email: UserTypeCommonFields['email'];
        avatar: UserTypeCommonFields['avatar'];
        avatarThumbnail: UserTypeCommonFields['avatarThumbnail'];
      }[]
    | [];
  recruiters:
    | {
        id: number;
        fullName: UserTypeCommonFields['fullName'];
      }[]
    | [];
  name: string;
  teamCash: number;
  teamCandidates: CandidateType[];
  teamOffer?: TeamOffer;
  teamOfferRecruiters: { id: number; fullName: string; email: string }[];
};

export type CandidateRefuse = {
  id: number;
  candidate_id: number;
  vacancy_id: number;
  reason: string;
  vacancies: {
    id: number;
    title: string;
    companies: CompaniesType[];
  };
  foundCandidate?: CandidateType;
  toClientSentDate?: string;
  offerDate?: string;
  createdAt: string;
  updatedAt: string;
};

export type PlaceToWork = {
  id: number;
  user_id: number;
  vacancy_id: number;
  status: string;
  applyDate: string;
  comment: string;
  createdAt: string;
  updatedAt: string;
  candidateComment?: string;
  toClientSentDate?: string;
  offerDate?: string;
  vacancy?: VacancyType;
  manager: UserTypeCommonFields[];
  candidateRefuse: CandidateRefuse[];
  isVisibleForClient: boolean;
  isAdminMarked: boolean;
};

export type HistoryType = {
  id: number;
  vacancy_id: number;
  candidate_id: number;
  comment: string;
  createdAt: string;
  authorRole: string;
  authorName: string;
  author_id: string;
  editable: boolean;
};

export type PaginationType = {
  paginationPerPage: number;
  paginationPage: number;
};

export type SortType = {
  sortBy: string;
  sortDirection: string;
};

export type FilterType = {
  country?: number[];
  city?: number[];
  category?: number[];
  subcategory?: number[];
  salary?: number[];
  currency?: number[];
  status?: number[];
  tags?: number[];
  search?: string;
  role?: string;
  specializations?: number[];
  skills?: number[];
  team?: number[];
  manager?: number[];
  companies?: number[];
};

export enum ClientTypeEnum {
  All = 'all',
  Percent = 'percent',
  TT = 'tt',
}

export type CompanyFormValues = {
  companyName: string;
  websiteUrl: string;
  contactPerson: string;
  contactPersonPosition: string;
  email: string;
  phone: string;
  clientType: ClientTypeEnum;
  previewImages: {
    logoUrl: string | null;
    workplacePhotos: ImagesPreviewType[];
    restzonePhotos: ImagesPreviewType[];
    teamPhotos: ImagesPreviewType[];
  };
  selectedImages: {
    logoUrl: string | null;
    workplacePhotos: string[];
    restzonePhotos: string[];
    teamPhotos: string[];
  };
  youTubeVideoUrl: string[];
  messengers: {
    messengerNickName: string;
    type: {
      label: string;
      value: number;
    } | null;
    id?: number;
  }[];
  manager: SelectType[] | null;
  workingConditionsFirstPart: string;
  workingConditionsSecondPart: string;
  annualPercentage: string;
  password: string;
  passwordConfirm: string;
  bankDetails: string;
  contract: string | null;
};

export type ImagesPreviewType = {
  name: string;
  previewLink: string;
};

export type SkillType = {
  id: number;
  title: string;
};

export type CandidateCountryType = {
  country_id: number;
  createdAt: string;
  flagImage: string;
  id: number;
  title_be: string;
  title_cz: string;
  title_de: string;
  title_en: string;
  title_es: string;
  title_fr: string;
  title_it: string;
  title_ja: string;
  title_lt: string;
  title_lv: string;
  title_pl: string;
  title_pt: string;
  title_ru: string;
  title_ua: string;
  updatedAt: string;
};

export type CandidateFormValues = {
  company: { label: string; value: number } | null;
  vacancy: { label: string; value: number } | null;
  candidateName: CandidateType['fullName'];
  email: CandidateType['email'];
  skills: CandidateType['skills'];
  specialization: SelectType | null;
  country: { label: any; value: any } | null | undefined;
  applyDate: string | null;
  candidateEmploymentDay: string | null;
  candidateProbationDay?: string | null;
  placeOfWork: CandidateType['placeOfWork'];
  comment?: string | null;
  cv: string | Blob | null | undefined;
  offerAmount: number | null;
};

export type RecruiterFormValues = {
  recruiterName: UserTypeCommonFields['fullName'];
  teams: SelectType[] | null;
  email: StringOrNull;
  phone: StringOrNull;
  subrecruiterClosedPositionCost: NumberOrNull;
  paymentDetails: StringOrNull;
  avatar: StringOrNull;
  closedVacancyCost: NumberOrNull;
  status: { label: string; value: string } | null;
  newRole: {
    label: string | UserRoleEnum;
    value: UserRoleEnum;
  } | null;
  password: string;
  passwordConfirm: string;
};

export type HrDirectorFormValues = {
  hrDirectorName: UserTypeCommonFields['fullName'];
  email: StringOrNull;
  phone: StringOrNull;
  paymentDetails: StringOrNull;
  avatar: StringOrNull;
  closedVacancyCost: NumberOrNull;
  newRole: {
    label: string | UserRoleEnum;
    value: UserRoleEnum;
  } | null;
  managers: { label: string; value: number }[] | null;
  status: { label: string; value: string } | null;
  password: StringOrNull;
  passwordConfirm: StringOrNull;
};

export type ConvertedHrdFormValues = {
  fullName: UserTypeCommonFields['fullName'];
  email: StringOrNull;
  phone: StringOrNull;
  paymentDetails: StringOrNull;
  avatar: StringOrNull;
  closedPositionCost: NumberOrNull;
  newRole: UserRoleEnum | null;
  managers: number[] | null;
  status: string | null;
  password: StringOrNull;
  passwordConfirm: StringOrNull;
};

export type ConvertedManagerFormValues = {
  fullName: UserTypeCommonFields['fullName'];
  email: StringOrNull;
  phone: StringOrNull;
  paymentDetails: StringOrNull;
  avatar: StringOrNull;
  closedPositionCost: NumberOrNull;
  newRole: UserRoleEnum | null;
  status: string | null;
  password: StringOrNull;
  passwordConfirm: StringOrNull;
};

export type ManagerFormValues = {
  managerName: UserTypeCommonFields['fullName'];
  email: StringOrNull;
  phone: StringOrNull;
  paymentDetails: StringOrNull;
  avatar: StringOrNull;
  closedVacancyCost: NumberOrNull;
  status: { label: string; value: string } | null;
  newRole: {
    label: string | UserRoleEnum;
    value: UserRoleEnum;
  } | null;
  password: StringOrNull;
  passwordConfirm: StringOrNull;
};

export type TeamFormValues = {
  teamName: string;
  recruiters: SelectType[] | null;
  managers: SelectType[] | null;
};

export type ConvertedTeamFormValues = {
  name: string;
  recruiters: number[] | null;
  managers: number[] | null;
  teamCash?: number | null;
};

export type LocationType = {
  id: number;
  relocate_location: boolean;
  createdAt: string;
  updatedAt: string;
  user_location: UserLocationType[];
  countries: CandidateCountryType[];
};

export type UserLocationType = {
  user_id: number;
  location_id: number;
  createdAt: string;
  updatedAt: string;
};

export type MonthDayTypeForModal = {
  id: number;
  name: string;
  vacancy: { title: string; id: number };
  candidateStatus: string;
  company: { title: string; id: number };
  recruiterId: number;
  managers: CompaniesType['manager'];
  sharedManagerIds: number[];
  refuseId?: number;
  date?: Date;
};

export type MonthDayType = {
  date: any;
  isCurrent: boolean;
  applyInfo?: {
    date: any;
    key: string;
    recruiter: string;
    recruiterId: number;
    candidateId: number;
    candidate: {
      fullName: UserTypeCommonFields['fullName'];
      candidateEmploymentDay: string | Date | null;
      offerAmount: number | null;
      placeToWork: PlaceToWork[];
    };
    sharedManagerIds: number[];
    forModal: MonthDayTypeForModal;
    teamCash: number;
    managerId: number;
    manager: UserTypeCommonFields['fullName'];
    companyId: number;
    company: string;
    isOffer: boolean;
    vacancyId: number;
    vacancy: title;
    companyName?: string;
    companyId?: number;
    isAdminMarked?: boolean;
    isCount?: boolean;
  }[];
};

export type AnimationSkip = {
  animationName: string;
  times: number;
};

export type Discount = {
  id: number;
  company_id: number;
  vacancy_candidates_id: number;
  discount: number;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
};
