import { createAsyncThunk } from '@reduxjs/toolkit';
import companyApi from 'api/companyApi';
import { setDiscount, togglePopup } from './reducer';

export const postLinkedin = createAsyncThunk(
  'discountPopup/postLinkedin',
  async (arg: number, { dispatch }) => {
    try {
      const { data } = await companyApi.updateDiscount(arg);
      
      dispatch(setDiscount(data.discount));
    } catch (err) {
      dispatch(togglePopup(false));
    }
  },
);

export const getDiscount = createAsyncThunk(
  'discountPopup/getDiscount',
  async (arg: number, { dispatch }) => {
    try {
      const { data } = await companyApi.getDiscount(arg);
      
      const discount = data?.discount ?? 0;
      dispatch(setDiscount(discount));
    } catch (err) {      
      dispatch(togglePopup(false));
    }
  },
);

export const closePopup = createAsyncThunk(
  'discountPopup/closePopup',
  async (arg: { id: number, userId: number }, { dispatch }) => {
    try {
      dispatch(togglePopup(false));
      await companyApi.closeDiscount(arg);
    } catch (err) {
      dispatch(togglePopup(false));
    }
  },
);

export const checkDiscount = createAsyncThunk(
  'discountPopup/checkDiscount',
  async (arg: void, { dispatch }) => {
    try {
      await companyApi.checkDiscount();
      dispatch(togglePopup(true));
    } catch (err) {
      dispatch(togglePopup(false));
    }
  },
);
